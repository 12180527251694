<template>
    <div>

        <div class="card" id="calculation">
            <h3 class="card_title">Kalkulation</h3>
            <div class="padding">
                <div id="contentGrid" class="indent">

                    <p><b>Losgröße:</b></p>
                    <input type="number" min="1" :value="information.lotsize" @input="changeValue('lotsize', $event.target.value)">

                    <p><b>Material</b></p><p></p>

                    <p class="indent">Materialname:</p>
                    <p>{{dict(material.name)}}</p>

                    <p class="indent">Materialtyp:</p>
                    <p>{{dict(material.type)}}</p>

                    <p class="indent">{{dict("price_"+material.type)}}:</p>
                    <p>{{material["price_"+material.type]}}€</p>

                    <p class="indent">Bedarf:</p>
                    <p>{{calcInfo.material.demand}}</p>

                    <p class="indent">Materialkosten:</p>
                    <p>{{calcInfo.material.cost}}€</p>

                    <p><b>Fertigungskosten</b></p><p></p>
                    <p class="indent">Maschine:</p>
                    <p>{{calcInfo.machines.name}}</p>

                    <p class="indent">Stundensatz:</p>
                    <p>{{calcInfo.machines.machinecost_hourly}}€/h</p>

                    <p class="indent">Fertigungsdauer:</p>
                    <p>{{calcInfo.manufacturing.time}}h</p>

                    <p class="indent">Fertigungskosten:</p>
                    <p>{{calcInfo.manufacturing.cost}}€</p>

                    <p class="indent">Rüstzeit:</p>
                    <p>{{calcInfo.setup.time}}min</p>

                    <p class="indent">Rüstkosten:</p>
                    <p>{{calcInfo.setup.cost}}€</p>

                    <p class="indent">Werkzeugkosten:</p>
                    <p>{{calcInfo.toolcost}}€</p>

                    <p><b>Sondereinzelkosten:</b></p>
                    <p>{{calcInfo.other.individual}}€/Stück</p>

                    <p class="textspacing"><b>Sondergemeinkosten:</b></p>
                    <p>{{calcInfo.other.overhead}}€</p>

                    <p><b>Herstellkosten:</b></p>
                    <p>{{calcInfo.cost.manufacturing}}€</p>                    

                    <p><b>Gewinn:</b></p>
                    <p>{{calcInfo.other.margin}}%</p>
                    <p><b>Rabatt:</b></p>
                    <p>{{calcInfo.other.discount}}%</p>
                    <p class="textspacing"><b>Skonto:</b></p>
                    <p>{{calcInfo.other.cashdiscount}}%</p>
                    <p></p><p></p>
                    <p><b>Gesamtpreis:</b></p>
                    <p>{{calcInfo.cost.total}}€</p>
                    <p><b>Stückpreis:</b></p>
                    <p>{{calcInfo.cost.piece}}€/Stück</p>

                </div>
            </div>
        </div>

        <div class="card" id="manufacturingTime">
            <h3 class="card_title">
                <indicator :condition="!verifiedSteps.processes" :ttle="verifiedSteps.processes" :required="1" :forcegray="!(opened.processes || verifiedSteps.processes)"></indicator>
                Fertigungszeit
            </h3>
            <div class="padding">
                <p class="textspacing">{{verifiedSteps.processes}}</p>
                <div id="timeGrid" class="indent">
                    <div>
                        <p><b>Prozess</b></p>
                        <p><b>Zeit</b></p>
                        <p><b>Korrektur</b></p>
                    </div>
                    <div v-for="(t, i) in times" :key="i">
                        <p class="indent">{{dict(t.type)}}:</p>
                        <p>{{t.time}}s</p>
                        <div v-if="t.type != 'toolchange'">
                            <p v-if="getCorrectedTime(t.type) >= 0">+</p>
                            <p v-else></p>
                            <input :class="{plus: getCorrectedTime(t.type)>=0}" type="number" step="5" min=-95 :value="getCorrectedTime(t.type)" @input="correctTime(t.type, $event.target.value)">
                            <p>%</p>
                        </div>
                    </div>
                    <div>
                        <p class="indent"><b>Gesamt:</b></p>
                        <p><b>{{$store.getters.time.toFixed(0)}}s</b></p>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            }
    },
    methods: {
        dict(c) {
            return this.$store.state.dict[c] || c
        },
        changeValue(key, value) {
            this.$store.commit("information_set", [key, value])
            this.$store.dispatch("calculate")
        },
        correctTime(type, value) {
            if(value < -95) value = -95
            this.$store.commit("time_correct", [type, value])
            this.$store.dispatch("calculate")
        },
        getCorrectedTime(type) {
            return this.$store.getters.times_corrected[type] || 0
        },
        addOwnData() {
            this.$store.state.calculation.information.ownData.push({name: "", value:""})
        },
        removeOwnData(i) {
            this.$store.state.calculation.information.ownData.splice(i,1)
        }
    },
    computed: {
        verify() {
            return this.$store.getters.verify
        },
        verifiedSteps() {
            return this.$store.getters.verifiedSteps
        },
        opened() {
            return this.$store.getters.verifyOpened
        },

        
        
        information() {
            return this.$store.state.calculation.information
        },
        material() {
            return this.$store.state.calculation.material
        },
        times() {
            let times = this.$store.getters.times
            times.forEach((sP) => {
                sP.time = sP.time.toFixed(0)
            });
            return times
        },
        times_corrected() {
            return this.$store.getters.times_corrected
        },
        price() {
            if (typeof this.$store.state.price == "number") {
                return this.$store.state.price.toFixed(2)+"€"
            }
            else return this.$store.state.price
        },
        calcInfo() {
            this.$store.dispatch("calculate")
            return this.$store.getters.calcInfo
        },
    }
}
</script>

<style scoped>
    #progress{
        max-width: 50%;
    }

    #timeGrid>div{
        display: grid;
        grid-template-columns: 1fr auto 6em;
        margin-bottom: 4px;
        grid-column-gap: 2em;
    }
    #timeGrid>div>div{
        display: grid;
        grid-template-columns: .5em 3em 1em;
    }
    #timeGrid>div>*:nth-child(2) {text-align-last: right;}
    #timeGrid>div>div>input{width: 2.5em;}
    #contentGrid{
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 1em;
        grid-row-gap: 8px;
    }

    #processes>div{margin-bottom: 4px;}
    #processes>div:nth-child(odd){
        background-color: rgb(241, 241, 241);
    }    
    #processes>div:nth-child(even){
        background-color: rgb(247, 247, 247);
    }
    /* #processes>div{
        display: flex;
    }
    #processes>div>p{
        margin: auto 0;
        height: fit-content;
    } */
</style>